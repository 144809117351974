//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiHome } from "@/api/apiHome";
export default {
  data() {
    return {
      list: [],
      currentPage: 1,
      changeSize: 18,
      total: 0,
      isEmpty: false
    };
  },
  components: {
    "no-data": () => import("@/pages/Home/components/noData")
  },
  created() {
    this.getDynamicList();
  },
  methods: {
    async getDynamicList() {
      const parmas = {
        limit: this.changeSize,
        page: this.currentPage
      };
      const res = await apiHome.getComDynamicDate(parmas);
      const { code, data, msg } = res.data;
      if (code == 200) {
        this.list = data.records || [];
        this.total = data.total;
        this.isEmpty = this.list.length == 0;
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDynamicList();
    },
    clickItem(item) {
      this.$router.push({
        path: "/dynamicDetail",
        query: {
          id: item.id
        }
      });
    }
  },
  filters: {
    datanumber2datetime(datetime, fmtdata) {
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    }
  }
};
