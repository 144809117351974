//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import apiRecommendCompany from "@/api/apiRecommendCompany"
import cookie from "@/storage/cookies.js"
export default {
  components: {
    TabMenuList: () => import('@/pages/companyType/components/TabList.vue'),
    CompayItemCell: () => import('@/pages/companyType/components/CompayItemCell.vue'),
    Empty: () => import('@/pages/companyType/components/Empty.vue')
  },
  data() {
    return {
      currentPage: 1,
      total: 0,
      companyTypeIndex: 0,
      loading: false,
      isEmpty: false,
      tabEnum: {
        cy: 0,
        hy: 1,
        tj: 2,
        gz: 3
      },
      tabList: [],
      list: []
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  watch: {
    user: {
      handler() {
        this.initData()
      },
      // immediate: true
    },
  },
  created() {
    this.initData()
  },
  mounted() {},
  methods:{
	  async initData(){
      // this.companyTypeIndex = this.tabEnum[this.$route.query.type]
      console.log('abc')
		  const res = await apiRecommendCompany.getCompanyTitle()
      const {code, data, msg} = res.data
      if(+code === 200) {
        this.tabList = data.map(item => {
          return {
            ...item,
            name: `${item.val}企业`,
            value: item.key,
          }
        })
        let typelist = data.map(item=>item.key)
        if(!(typelist.includes(this.tabEnum[this.$route.query.type]))){
            let path = ''
            for(let k in this.tabEnum) {
              if(this.tabEnum[k] === data[0]['key']) {
                path = '/companyType?type='+ k
                this.companyTypeIndex = this.tabEnum[k]
              }
            }
            this.$router.replace({
              path: path
            })
        } else {
          this.companyTypeIndex = this.tabEnum[this.$route.query.type]
        }
        this.getList()
        return
      }
	  },
    async getList(type = '') {
      this.list = []
      this.loading = true
      let currentArea = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : ""
      const parmas = {
        limit: 20,
        page: this.currentPage,
        type: type ? type : this.companyTypeIndex,
        areaCode: currentArea ? currentArea.id : 650000,
        businessType: 1, // 业务类型:1首页 2商品 3招标 4集采 5租赁 6工队 7物流 8会员 9经营 10企服 11招聘 12吃住行 13招商引资 14商机
      }
      const res = await apiRecommendCompany.getAllIndexPage(parmas)
      const {code, data, msg} = res.data
      if(+code === 200) {
        this.total = data.total
        this.list = data.records
        this.isEmpty = data.records.length === 0
      }
      this.loading = false
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },
    async clickMenuItem(data) {
      this.currentPage = 1
      this.companyTypeIndex = data.key
      let path = ''
      for(let k in this.tabEnum){
        if(this.tabEnum[k] === data['key']){
          path = k
        }
      }
      this.$router.replace('/companyType?type='+path)
      // console.log('abc')
      this.getList()
    },
  }
};
