//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiHome } from "../../api/apiHome";
import moment from "moment";
export default {
  data() {
    return {
      // title:'',
      // subheading:'',
      // time:'',
      // content:''
      detailInfo: {}
    };
  },
  created() {
    this.getDetai();
  },
  computed: {
    getTime() {
      let showTime = this.detailInfo.createTime;
      return moment(showTime).format("YYYY-MM-DD HH:mm:ss");
    }
  },
  filters: {
    datanumber2datetime(datetime, fmtdata) {
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    }
  },
  methods: {
    // 获取动态信息
    async getDetai() {
      let parmas = {
        id: this.$route.query.id
      };
      let res = await apiHome.getDetailInfo(parmas);
      let { code, msg, data } = res.data;
      if (code == 200) {
        this.detailInfo = data;
      }
    }
  }
};
